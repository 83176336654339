import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DataFetch from './components/dataFetch';
import Detail from './components/Detail';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import NavBar from './components/NavBar';
import './App.css'

function App() {
  return (
    <Router>
      
        <Row>
          <NavBar />
          <Container>
          <Routes>
            <Route path="/" element={<DataFetch />} />
            <Route path="/detail/:id" element={<Detail />} />
          </Routes>
          </Container>
        </Row>
     
    </Router>
  );
}

export default App;