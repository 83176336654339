import React, { useEffect, useState } from "react";
import { Container, Table, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Image from 'react-bootstrap/Image';
import Detail from "./Detail";
import { Routes, Route, useNavigate } from "react-router-dom";
import { FaFireBurner } from "react-icons/fa6";
import { GrSystem } from "react-icons/gr";
import { PiGauge } from "react-icons/pi";
import { AiOutlineSecurityScan } from "react-icons/ai";
import { FaNetworkWired } from "react-icons/fa";
import { SiSession } from "react-icons/si";
import { CiSettings, CiSearch, CiBoxList, CiFilter } from "react-icons/ci";

export default function DataFetch() {
  const [data, setData] = useState([]);
  const navigate = useNavigate(); // Use the useNavigate hook
  const [filterKey, setFilterKey] = useState('');
  const [filterValue, setFilterValue] = useState('');

  useEffect(() => {
    fetch('https://paoss.insecure.pt/api/firewalls')
      .then(response => response.json())
      .then(parsedData => {
        // Remove commas and convert numeric values to floats in the fetched data
        const cleanedData = parsedData.map(item => {
          const cleanedItem = { ...item };
          for (const key in cleanedItem) {
            if (typeof cleanedItem[key] === "string" && !isNaN(cleanedItem[key].replace(",", ""))) {
              cleanedItem[key] = parseFloat(cleanedItem[key].replace(",", ""));
            }
          }
          return cleanedItem;
        });
        setData(cleanedData);
     
      });
  }, []);


  const toChild = (item) => {
    console.log(item.model)
    navigate(`/detail/${item.model}`, { state: { item } }); // Use navigate to change the route
  };

  // Custom sorting function to extract the numeric value and sort in ascending order
  const customSort = (a, b) => {
    const extractNumber = (value) => {
      const match = value.match(/\d+/); // Extract numeric part using regex
      return match ? parseInt(match[0], 10) : 0; // Parse the extracted number
    };

    const aValue = extractNumber(a["App-ID firewall throughput"]);
    const bValue = extractNumber(b["App-ID firewall throughput"]);

    return aValue - bValue; // Sort based on the extracted numeric value
  };

  // Sort the data using the custom sorting function


  const filteredData = data.filter((item) => {
    if (filterValue === "" || filterKey === "") {
      return true; // Return true when no filter value or filter key is entered
    }

    const itemValue = item[filterKey];
    if (typeof itemValue === "number") {
      // Filter for numeric values
      return itemValue >= parseFloat(filterValue);
    } else if (typeof itemValue === "string") {
      // Filter for string values
      return itemValue.toLowerCase().includes(filterValue.toLowerCase());
    } else {
      return false; // Return false for other data types
    }
  });

  const sortedData = filteredData.sort(customSort);

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <Container className="p-4">
      <div className="row">
      <div className="col">
  <Form.Group>
    <Form.Label> <CiSearch color="OrangeRed " fontSize="1.2em" /> Search by feature</Form.Label>
    <Form.Control
      as="select"
      value={filterKey}
      onChange={(e) => setFilterKey(e.target.value)}
    >
      <option value="">Select a filter</option>
      {data.length > 0 && Object.keys(data[0]).map((key) => {
        if (key !== "5G" && key !== "802" && key !== "_id" && key !== "model" && key !== "large-image-url" 
          && key !== "url" && key !== "Threat prevention throughput" && key !== "App-ID firewall throughput") {
          return (
            <option key={key} value={key}>
              {key}
            </option>
          );
        }
        return null;
      })}
    </Form.Control>
  </Form.Group>
</div>
        <div className="col">
          <Form.Group>
            <Form.Label><CiBoxList color="OrangeRed " fontSize="1.2em" /> Value:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter filter value"
              value={filterValue}
              onChange={(e) => {
                const newValue = e.target.value;
                if (newValue !== filterValue) {
                  setFilterValue(newValue);
                }
              }}
            />
          </Form.Group>
        </div>
      </div>
      <Table  hover className="custom-class">
        <thead>
          <tr>
            <th></th>
            <th><FaFireBurner color="OrangeRed" fontSize="1em" /> Model</th>
            <th> <GrSystem color="OrangeRed" fontSize="1em" /> FW  </th>
            <th><PiGauge color="OrangeRed" fontSize="1em" /> TP  </th>
            
            <th><AiOutlineSecurityScan color="OrangeRed" fontSize="1em" /> IPSec </th>
            <th><CiSettings color="OrangeRed" fontSize="1.5em" />  Interfaces</th>
            <th><FaNetworkWired color="OrangeRed" fontSize="1.2em" /> Con/s </th>
            <th><SiSession color="OrangeRed" fontSize="1em" />  Max sessions </th>
            <th><CiSettings color="OrangeRed" fontSize="1.5em" />  VSYS/Max VSYS</th>

            <th>{filterKey}</th>
          </tr>
        </thead>
        <tbody >
          {sortedData.map((item) => (
            <tr className="success" key={item._id} onClick={() => toChild(item)}>
              <td><Image src={`https://paloaltonetworks.com/${item["large-image-url"]}`} thumbnail /></td>
              <td>{item.model.toUpperCase()}</td>
              <td>{item["App-ID firewall throughput"]}</td>
              <td>{item["Threat prevention throughput"]}</td>
              <td>{item["IPSec VPN throughput"]}</td>
              <td>
              {item["Traffic - 1Gbps SFP"] && item["Traffic - 1Gbps SFP"] !== 'NA' ? <p className="text-truncate" style={{ maxWidth: "19ch" }}>1Gbps SFP: {item["Traffic - 1Gbps SFP"]}</p>  : null}
              {item["Traffic - 10/100/1000"] && item["Traffic - 10/100/1000"] !== 'NA' ? <p> 1Gbps: {item["Traffic - 10/100/1000"]}</p> : null}          
              {item["Traffic - 10Gbps SFP+"] && item["Traffic - 10Gbps SFP+"] !== 'NA'  ? <p> 10Gbps SFP+: {item["Traffic - 10Gbps SFP+"]}</p> : null}        
              {item["Traffic - 25Gbps SFP28"] && item["Traffic - 25Gbps SFP28"] !== 'NA'   ? <p> 25Gbps SFP28: {item["Traffic - 25Gbps SFP28"]}</p> : null}
              {item["Traffic - 40/100Gbps QSFP+/QSFP28"] && item["Traffic - 40/100Gbps QSFP+/QSFP28"] !== 'NA'   ? <p> 40/100Gbps: {item["Traffic - 40/100Gbps QSFP+/QSFP28"]}</p> : null}           
              {item["Traffic - 100/1000/10000"] && item["Traffic - 100/1000/10000"] !== 'NA'   ? <p>100/1000/10000: {item["Traffic - 100/1000/10000"]}</p> : null}           
           


                 </td>
              <td>{item["Connections per second"]}</td>
              <td>{item["Max sessions (IPv4 or IPv6)"]}</td>
              <td>{item["Base virtual systems"]}/{item["Max virtual systems"]}</td>
              {item[filterKey] && <td>< CiFilter color="OrangeRed " fontSize="1em" /> {item[filterKey]}</td>}
            </tr>
          ))}
        </tbody>
        
        {/* 1Gbps SPF {item["Traffic - 1Gbps SFP"]} <p>1Gbps {item["Traffic - 10/100/1000"]}</p> 10Gbps SFP+ {item["Traffic - 10Gbps SFP+"]} */}

        
      </Table>
      <Routes>
        <Route
          path="/detail/:id"
          element={<Detail data={data} />}
        />

      </Routes>
    </Container>
  );
}