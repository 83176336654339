import React from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Row, Table, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

const Detail = () => {
    //const { id } = useParams();
    const location = useLocation();
    const item = location.state?.item; // Access the passed item from the state
    console.log(item)

    if (!item) {
        return <div>No data found for this item.</div>;
    }
    const filteredKeys = Object.keys(item).filter((key) => key !== "802"
        && key !== "_id"
        && key !== "model"
        && key !== "large-image-url"
        && key !== "url"
        && item[key] !== "NA"
    );

      filteredKeys.sort((a, b) => {
        if (item[a]["Threat prevention throughput"] && item[b]["Threat prevention throughput"]) {
          return item[a]["Threat prevention throughput"].localeCompare(item[b]["Threat prevention throughput"]);
        }
        return 0;
      });

    const handleClick = () => {
        const externalUrl = `https://paloaltonetworks.com/${item.url}`
        window.open(externalUrl, '_blank');
    };

    return (
        <Container>
            <Row>
            <Col className="p-4 text-center">
                    <Card style={{ width: '20rem' }}>
                        <Card.Img variant="top" src={`https://paloaltonetworks.com/${item["large-image-url"]}`} />
                        <Card.Body>
                            <Card.Title>{item.model.toUpperCase()}</Card.Title>
                            <Card.Text>
                                FW throughput: {item["App-ID firewall throughput"]}
                                <br />
                                ATP throughput {item["Threat prevention throughput"]}
                            </Card.Text>
                            <Button variant="secondary" onClick={handleClick} >{item.model.toUpperCase()}</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className= "p-4">
                    <Table hover>
                        <thead>
                            <tr>
                                <th>Feature</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredKeys.map((key) => (
                                <tr key={key}>
                                    <td>{key}</td>
                                    <td>{typeof item[key] === 'object' ? JSON.stringify(item[key]) : item[key]}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
               


            </Row>
        </Container>
    );
};



export default Detail;